<template>
<div class="page_content_wrap">
	<div class="plannin_wrap py-3 mt-4">
		<div class="container">
			<div class="state_content_box mt-3 text-center">
	            <h2 class="text-dark">Planning Recommendations</h2>
	            <p class="font-size-sm text-dark m-0">
                    Here are a few major things to plan for.
				</p>
			</div>
		</div>
	</div>
    <div class="recommendation-section pb-3 w-70 m-auto">
        <div class="container">
            <div class="row align-items-center border bg-white shadow mb-5">
                <div class="col-4">
                    <div class="recommendation-img text-center">
                        <img src="/images/recommendation.svg" alt="no-images" >
                    </div>
                </div>
                <div class="col-8">
                    <div class="recommendation-contant pt-3">
                        <div class="recommendation-top">
                            <p class="badge badge-pastel-primary p-2 mb-1 rounded">Recommendations for Married Couples</p>
                        </div>
                        <div class="recommendation-text text-dark">
                            <h3>Recommendations for Married Couples</h3>
                            <p>Married couples often wonder what will happen to the finances after one spouse passes. Building a plan with Heyday will include succession etc…</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row align-items-center border bg-white shadow mb-5">
                <div class="col-4">
                    <div class="recommendation-img text-center">
                        <img src="/images/potential.svg" alt="no-images">
                    </div>
                </div>
                <div class="col-8">
                    <div class="recommendation-contant pt-3 p-3">
                        <div class="recommendation-top">
                            <p class="badge badge-pastel-primary p-2 mb-1 rounded">Most Popular</p>
                        </div>
                        <div class="recommendation-text text-dark">
                            <h3>High potential expenses</h3>
                            <p>Your main concerns could cost (X). An income plan will help determine if you need to reduce your cost of living to prepare for it etc…</p>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="row align-items-center border bg-white shadow mb-5">
                <div class="col-4">
                    <div class="recommendation-img text-center">
                        <img src="/images/covered.svg" alt="no-images">
                    </div>
                </div>
                <div class="col-8">
                    <div class="recommendation-contant pt-3 p-3">
                        <div class="recommendation-top">
                            <p class="badge badge-pastel-primary p-2 mb-1 rounded">This potential expense is not covered by your income</p>
                        </div>
                        <div class="recommendation-text text-dark">
                            <h3>Potential expense is not covered by income</h3>
                            <p>Your Social Security and pensions cover your fixed expenses, but your main concern, (X), could cost (X) and would come out of your savings. To plan for paying for this, etc…</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>

    <div class="plannin_wrap bg-darkblue py-5">
		<div class="container">
			<div class="mt-3 text-center text-white">
                <p class="font-size-sm mb-2">Next Steps</p>
	            <h2 class="mb-3">Discuss this with a Heyday Consultant</h2>
                <h6 class="mb-3">Our team has helped hundreds secure reliable income throughout retirement for over 20 years.</h6>
                <h1><a class="text-white mb-3" href="tel:1-844-4-0000"><i class="fas fa-phone-volume mr-2"></i>1-844-4-HEYDAY</a></h1>
	            <p class="">Weekdays, 8 AM - 7 PM (ET)</p>
			</div>
		</div>
	</div>

</div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";

export default {
  name: 'Screen12',
    data() {
        return {
            isLoading: true,
            isSubmitting: false,
            errors: []
        }
    },
    computed: {
        ...mapGetters("wizard", {
            survey: "getSurvey"
        }),
    },
    mounted() {
        this.isLoading = false;
    },
    methods: {
    }
};
</script>
